import React from 'react'
import { scroller } from 'react-scroll';
import './NewHome.css'
import logo from '../../images/logo.png'
import { useState, useRef} from 'react';
import { useEffect } from 'react';
import commercialVideo from '../../images/commercialVideo.mp4'
// import modalIcon1 from '../images/modalIcon1.png'
// import modalIcon2 from '../images/modalIcon2.png'
// import modalIcon3 from '../images/modalIcon3.png'
// import modalIcon4 from '../images/modalIcon4.png'



function NewHome() {

    const scrollTo = (target) => {
        scroller.scrollTo(target, {
          duration: 200,
          delay: 5,
          smooth: true,
        })
      }

  const videoRef = useRef(null);


  return (
    <div className='homeContainer' >
         <video
      className='video'
      style={{objectFit:"cover"}}
      // style={{width:'100%', height:'70%',}}
      ref={videoRef}
      playsInline
      webkit-playsinline="true"
       autoPlay 
       loop
       muted
        id='video'
        >
        <source src={commercialVideo} type='video/mp4'/>
      </video>
        <div className='homeBody'>
            <a href='/' style={{textDecoration:'none'}}>
            <div className='newHomeLogoContainer'>
                <img className='logoImage' src={logo}/>
                <h5 className='logoHeadline'>iLand Tech <br/> Martha's Vineyard</h5>
            </div>
            </a>
            <div className='modalWindow'>
                <h1 className='modalHeading'>Professional Tech Service <br/> Martha's Vineyard</h1>
                <h5 className='modalHeading-2'>For Businesses & Individuals</h5>
                <h2 className='modalNumber'>(508) 939-0844</h2>
                <h5 className='modalEmail'>support@ilandtech.com</h5>
                <button className='button-services' onClick={() => scrollTo('serviceContainer')}>Services</button>
                <a href='/about'><button className='button-about'>About Us</button></a>
                <a target='_blank' href='https://www.instagram.com/ilandtechmv/'><button className='button-gallery'>Gallery</button></a>


               
            </div>
        </div>
    </div>
  )
}

export default NewHome