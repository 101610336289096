import styledEngine from '@mui/styled-engine';
import React from 'react';
import styled from 'styled-components';
import Service from '../Components/Service';
import routerLogo from '../images/router-decos.png';
import cameraLogo from '../images/security-camera.png';
import smartHome from '../images/smart-home.png';
import computerLogo from '../images/computer-logo.png';
import AVLogo from '../images/AVLogo.png';
import cloudLogo from '../images/cloudLogo.png'
import BgImg from '../images/services-background.jpeg';
import {AiOutlineColumnWidth} from 'react-icons/ai'
import Footer from '../Components/Footer';
import './Services.css';

export const PageContainer = styled.div`
width:100vw;
// border-top:2px solid;
border-color:#007791;

@media screen and (max-width: 650px){
  border-top:none;
}

`

export const PageBody =styled.div`
width:100%;
height:100%;
// background:blue;
display:grid;
overflow-wrap: anywhere
margin-top:10px;
// background-image: url(${BgImg});
// background-size:cover;
// background-position:center;
`
export const BodyHeader=styled.div`
margin-top:2rem;
width:100%;
height:80%;
display:flex;
flex-direction: column;
// background:red;
justify-content:center;
align-items:center;
text-align:center;
color:black;

`

export const ServicesContainer=styled.div`
width:100%;
height:100%;
display:flex;
flex-wrap: wrap;
justify-content: space-between; 
backround:yellow;
// overflow:auto;
margin-top:2rem;
margin-bottom:2rem;
padding-bottom:5rem;

color:black;



@media screen and (max-width: 650px){
    display:inline-block;
    justify-content:center;
    padding:1px;
  }
`



function Services() {
  return (
    <PageContainer>
        <PageBody>
            <BodyHeader className='serviceContainer'>
                <h2 style={{color:"#0CC0DF"}} className="font-face-gm">WE TAKE CARE OF ANY TECH RELATED ISSUES</h2>
                {/* <h6>Internet <AiOutlineColumnWidth /> Computers <AiOutlineColumnWidth />  Phones <AiOutlineColumnWidth /> Television <AiOutlineColumnWidth /></h6> */}
                
            </BodyHeader>

            <ServicesContainer>
                <Service title="Networking/Wi-Fi Solutions" img={routerLogo} url={('/wifi')} alt='service1' />
                <Service title="Security Cameras" img={cameraLogo} url={('/cameras')} alt='service2' />
                <Service title="Smart Home Integration" img={smartHome} url={('/smartHome')} alt='service3' />
                <Service title="Computers / Printers "img={computerLogo} url={('/computers')} alt='service4' />
                <Service title="Audio / Visual Setup"img={AVLogo} url={('/av')} alt='service5' />
                <Service title="Cloud Services"img={cloudLogo} url={('/cloud')} alt='service6' />

            </ServicesContainer>
        </PageBody>
    </PageContainer>
  )
}

export default Services