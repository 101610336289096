import React, { useState, useEffect } from "react";
import { database } from "../firebase";
import { onValue, ref, push } from "firebase/database";
// import DayTimePicker from '@mooncake-dev/react-day-time-picker';
import DayTimePicker from './DayTimePicker';
import styled from "styled-components";
import "arrive";
import './Calendar.css'

// const Container = styled.div`
//   width: 475px;
//   margin: 1em auto;
//   padding: 1em;
//   background-color: #fff;
//   color: #333;
//   border: 1px solid #f0f0f0;
//   border-radius: 5px;
//   text-align: center;
//   box-shadow: 0 2px 4px #00000018;
//   @media (max-width: 520px) {
//     width: 100%;
//   }`;
const DarkContainer = styled.div`
  width: 475px;
  margin: 1em auto;
  padding: 1em;
  background-color: white; // colorze gadaakete da gascordeba?!
  color: #000;
  border-radius: 50px;
  text-align: center;
  opacity: 0.9;
  @media (max-width: 520px) {
      width: 100%;
    }
    `;

const theme = {
    primary: 'blue',
    secondary: 'slategrey',
    background: 'white', // This should match the container background (image-ze ar mushaobs, marto colorze)
    buttons: {
        disabled: {
            color: '#333',
            background: '#f0f0f0'
        },
        confirm: {
            color: '#fff',
            background: 'slategrey',
            hover: {
                color: '',
                background: 'lightslategrey'
            }
        }
    },
    feedback: {
        success: {
            color: '#29aba4'
        },
        failed: {
            color: '#eb7260'
        }
    }
};


const Calendar = () => {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [daysOff, setDaysOff] = useState([]);
    // const [userName, setUserName] = useState('');
    // const [userEmail, setUserEmail] = useState('');
    // const [userPhone, setUserPhone] = useState('');
    // const [eventTitle, setEventTitle] = useState('');
    // const [userAddress, setUserAddress] = useState('');
    const [isScheduling, setIsScheduling] = useState(false);
    const [isScheduled, setIsScheduled] = useState(false);
    const [scheduleErr, setScheduleErr] = useState('');

    useEffect(() => {
        const fetchEvents = async () => {
            const eventsRef = ref(database, "events");
            onValue(eventsRef, (snapshot) => {
                const data = snapshot.val();
                const eventsArray = [];
                for (let key in data) {
                    if (data[key].approved) eventsArray.push(data[key]);
                }
                setCalendarEvents(eventsArray);
            });
        };

        const fetchDaysOff = async () => {
            const daysOffRef = ref(database, "days_off");
            onValue(daysOffRef, (snapshot) => {
                const data = snapshot.val();
                const daysOffArray = [];
                for (let key in data) {
                    daysOffArray.push(data[key]);
                }
                setDaysOff(daysOffArray);
            })
        }

        fetchEvents();
        fetchDaysOff();

        document.arrive('li', function () {
            const matchingElements = [];
            const liElements = document.querySelectorAll('li');

            liElements.forEach(element => {
                const textContent = element.textContent.trim();
                const regex = /^(0[0-9]|1[0-4]):00$/;

                if (regex.test(textContent)) {
                    matchingElements.push(element);
                }
            });

            matchingElements.forEach(element => {
                element.style.display = "none";
            });
        });

        return () => {
            document.unbindArrive();
        };
    }, []);

    const timeSlotValidator = (slotTime) => {
        const eveningTime = new Date(
            slotTime.getFullYear(),
            slotTime.getMonth(),
            slotTime.getDate(),
            14,
            0,
            0
        );
        let alreadyBooked = false;
        for (let i = 0; i < calendarEvents.length; i++) {
            if (calendarEvents[i].dateTime === slotTime.getTime() && (calendarEvents[i].approved === "pending" || calendarEvents[i].approved === "true")) alreadyBooked = true;
        }

        const isDayOff = (slotTime) => {
            const slotDate = new Date(slotTime);
            const slotDateMidnight = new Date(slotDate.getFullYear(), slotDate.getMonth(), slotDate.getDate()).getTime();

            return daysOff.some(dayOff => {
                const dayOffDate = new Date(dayOff.year, dayOff.month - 1, dayOff.day).getTime();
                return slotDateMidnight === dayOffDate;
            });
        };


        const isValid = !isDayOff(slotTime) && (slotTime.getTime() > eveningTime.getTime()) && !alreadyBooked;
        return isValid;
    }


    const handleSubmit = (event) => {
        if (!event.eventDescription || !event.userName || !event.userEmail || !event.userPhone || !event.userAddress || !event.dateTime) {
            setScheduleErr('Please fill out all fields before scheduling.');
            return;
        }
        setIsScheduling(true);
        setScheduleErr('');

        // const name = userName;
        // const email = userEmail;
        // const phone = userPhone;
        // const address = userAddress;
        // const description = eventTitle;
        // const dateTime = parseInt(Date.parse(event), 10);

        // const newEvent = {
        //     eventDescription: description,
        //     userName: name,
        //     userEmail: email,
        //     userPhone: phone,
        //     userAddress: address,
        //     dateTime: dateTime,
        //     approved: "pending", // Set to pending initially
        // };

        try {
            // console.log(event);
            const eventsRef = ref(database, "events");
            push(eventsRef, event);
            setIsScheduled(true);
        } catch (e) {
            setScheduleErr(e);
        } finally {
            setIsScheduling(false);
        }
        // console.log("Sending request to Database with these details:");
        // console.log("Event time: ", event);
        // console.log(newEvent);


    };

    return (
        <div className="calendar">
            <DarkContainer>
                {/* {
                    !isScheduled && <div className="inputs-container">
                        <input className="name-input" type="text" id="name" name="name" required onChange={(e) => setUserName(e.target.value)} placeholder="Your Name" />
                        <input className="email-input" type="text" id="email" name="email" required onChange={(e) => setUserEmail(e.target.value)} placeholder="Your Email" />
                        <input className="phone-input" type="text" id="phone" name="phone" required onChange={(e) => setUserPhone(e.target.value)} placeholder="Your Phone Number" />
                        <input className="address-input" type="text" id="address" name="address" required onChange={(e) => setUserAddress(e.target.value)} placeholder="Your Address" />
                        <input className="problem-description-input" type="text" id="title" name="title" required onChange={(e) => setEventTitle(e.target.value)} placeholder="Describe your problem here" />
                    </div>
                } */}
                <DayTimePicker timeSlotSizeMinutes={60}
                    onConfirm={e => handleSubmit(e)}
                    timeSlotValidator={timeSlotValidator}
                    isLoading={isScheduling}
                    isDone={isScheduled}
                    err={scheduleErr}
                    theme={theme}
                    doneText={`Your appointment has been sent to iLandTech team for the review! You should expect a response shortly.`} />
            </DarkContainer>
        </div>
    );
};

export default Calendar;
