import React from 'react';
import { scroller } from 'react-scroll';
import './Av.css';
import logo from '../../images/logo2.png';



function NewHome() {

  return (
    <div className='avContainer' >
        <div className='avBody'>
            <a href='/' style={{textDecoration:'none'}}>
            <div className='avLogoContainer'>
                <img className='logoImage' src={logo}/>
                <h5 className='cameraLogoHeadline'>iLand Tech <br/> Martha's Vineyard</h5>
            </div>
            </a>
            <div className='modalWindow'>
                <h1 className='modalHeading'>Audio / Visual Setup</h1>
                <div className='modalNavbarContainer'>
                <a href='/'><button className='navbar-button'>Main</button></a>
                <a href='/wifi'><button className='navbar-button'>WiFi</button></a>
                <a href='/smartHome'><button className='navbar-button'>Automation</button></a>
                <a href='/computers'><button className='navbar-button'>Computers</button></a>
                <a href='/cameras'><button className='navbar-button'>Cameras</button></a>
                <a href='/cloud'><button className='navbar-button'>Cloud</button></a>
                </div>
                <p className='avModalParagraph'>Elevate your entertainment experience with our AV Installation services. Our skilled technicians bring audio and visual excellence to your space by seamlessly installing in-wall speakers, mounting TVs, and creating a harmonious AV setup. Whether it's a home theater or a commercial space, we create solutions to maximize audio clarity and visual impact. Trust us to transform your environment into a haven of immersive entertainment.</p>

               
            </div>
        </div>
    </div>
  )
}

export default NewHome