import React from 'react';
import './Plans.css';

const plans = [
  {
    name: "Basic",
    price: "",
    features: [
      "✔ Networking/WiFi Solutions",
      "✔ Networking/WiFi Solutions",
      "✔ Computer Setup & Maintenance",
      "✔ Printers/Scanners Setup",
      "✔ TVs Setup/Mounting",
      "✔ Domain/Email Setup",
      "✔ IT Consultation (Basic)",
    ]
  },
  {
    name: "Pro",
    price: "",
    features: [
      "✔ Everything in Basic Plan",
      "✔ Everything in Basic Plan",
      "✔ Home/Office Telephone Setup",
      "✔ Website Optimization",
      "✔ User Database Setup & Maintanance (Basic)",
      "✔ Audio/Visual Setup & Maintenance",
      "✔ Advanced IT Support 24/7",
    ]
  },
  {
    name: "VIP",
    price: "Custom",
    features: [
      "✔ Everything in Pro Plan",
      "✔ Everything in Pro Plan",
      "✔ Advanced Cloud Management",
      "✔ Advanced Security Solutions (CYBERSECURITY)",
      "✔ Complete Audio/Visual & Smart Home Integration",
    ]
  }
];

const plans2 = [
  {
    name: "Basic",
    price: "",
    features: [
      "✔ Networking/WiFi Solutions",
      "✔ Networking/WiFi Solutions",
      "✔ Computer Setup & Maintenance",
      "✔ Printers/Scanners Setup",
      "✔ TVs Setup/Mounting",
      "✔ Domain/Email Setup",
      "✔ Basic Cloud Management",
      "✔ IT Consultation",
    ]
  },
  {
    name: "Pro",
    price: "",
    features: [
      "✔ Everything in Basic Plan",
      "✔ Everything in Basic Plan",
      "✔ Security Cameras",
      "✔ Home/Office Telephone Setup",
      "✔ Website Optimization",
      "✔ User Database Setup & Maintanance (Basic)",
      "✔ Audio/Visual Setup & Maintenance",
      "✔ Smart Tech Integration",
      "✔ Advanced IT Support 24/7",
    ]
  },
  {
    name: "VIP",
    price: "Custom",
    features: [
      "✔ Everything in Pro Plan",
      "✔ Everything in Pro Plan",
      "✔ Advanced Cloud Management",
      "✔ Advanced Security Solutions (CYBERSECURITY)",
      "✔ Complete Audio/Visual & Smart Home Integration",
      "✔ Large office Audio/Visual Setup ",
    ]
  }
]

const Plans = () => {
  return (
    <div>
      <header className="page-header">Business Plans</header>
      <div className="plans-container">
        {plans.map((plan, index) => (
          <div key={index} className="plan">
            <div className="plan-header">
              <div className="plan-title">{plan.name}</div>
              <div className="plan-price">{plan.price}</div>
            </div>
            <ul className="plan-features">
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            {/* <button className="select-button">Select</button> */}
          </div>
        ))}
      </div>

                {/* *******-------Medium Size Plans-------********* */}

      {/* <header className="page-header">Medium Size Business Plans</header> */}
      
    </div>
  );
};

export default Plans;
