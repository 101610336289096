import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dateFns from 'date-fns';
import { ThemeProvider } from 'styled-components';

import './InputFields.css';

import { PopupWrapper, Popup, PopupHeader, PopupClose } from './Popup';
import { ConfirmButton } from './Confirm';
import { DayIcon, ClockIcon, SuccessIcon, FailedIcon } from './Icons';
import { Success, Failed } from './Feedback';
import { FormControl, TextField, FormHelperText } from '@mui/material';

import Calendar from './calendar';
import TimeSlots from './time-slots';

import { preventPastDays } from './validators';

function DayTimePicker({
  timeSlotValidator,
  timeSlotSizeMinutes,
  isLoading,
  isDone,
  err,
  onConfirm,
  confirmText,
  loadingText,
  doneText,
  theme
}) {
  const [pickedDay, setPickedDay] = useState(null);
  const [pickedTime, setPickedTime] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [eventTitle, setEventTitle] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [showPickTime, setShowPickTime] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [userEmailError, setUserEmailError] = useState(false);
  const [userPhoneError, setUserPhoneError] = useState(false);
  const [userAddressError, setUserAddressError] = useState(false);
  const [eventTitleError, setEventTitleError] = useState(false);
  const [generalErr, setGeneralErr] = useState('');


  const handlePickDay = day => {
    setPickedDay(day);
    setShowPickTime(true);
  };

  const handlePickTime = time => {
    setPickedTime(time);
    setShowPickTime(false);
    setShowConfirm(true);
  };

  const handleClosePickTime = () => {
    setShowPickTime(false);
  };

  const handleConfirm = () => {
    let validUserName = !!userName;
    let validEmail = userEmail && /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(userEmail);
    let validPhone = !!userPhone;
    let validAddress = !!userAddress;
    let validEventTitle = !!eventTitle;

    setUserNameError(!validUserName);
    setUserEmailError(!validEmail);
    setUserPhoneError(!validPhone);
    setUserAddressError(!validAddress);
    setEventTitleError(!validEventTitle);

    if (validUserName && validEmail && validPhone && validAddress && validEventTitle) {
      const event = {
        eventDescription: eventTitle,
        userName: userName,
        userEmail: userEmail,
        userPhone: userPhone,
        userAddress: userAddress,
        dateTime: parseInt(Date.parse(pickedTime), 10),
        approved: "pending", // Set to pending initially
      };
      onConfirm(event);
      setGeneralErr('');
    } else {
      setGeneralErr("Please fill out all fields before scheduling.");
    }
  };

  const handleCloseConfirm = () => {
    setUserNameError(false);
    setUserEmailError(false);
    setUserPhoneError(false);
    setUserAddressError(false);
    setEventTitleError(false);
    setGeneralErr('');
    setShowConfirm(false);
    setShowPickTime(true);
  };



  return (
    <ThemeProvider theme={theme}>
      <PopupWrapper>
        <Calendar validator={preventPastDays} pickDay={handlePickDay} />

        {showPickTime && (
          <Popup>
            <PopupHeader>
              <p>
                <DayIcon /> {dateFns.format(pickedDay, 'dddd, MMMM Do, YYYY')}
              </p>
              {/* <p>
                <button className='goBackButton' onClick={handleClosePickTime}>Go Back</button >
              </p> */}
            </PopupHeader>

            <TimeSlots
              pickedDay={pickedDay}
              slotSizeMinutes={timeSlotSizeMinutes}
              validator={timeSlotValidator}
              pickTime={handlePickTime}
            />
             <p>
                <button className='goBackButton1' onClick={handleClosePickTime}>Go Back</button >
              </p>
          </Popup>
        )}

        {showConfirm && (
          <Popup>
            <PopupHeader>
              <p>
                <DayIcon /> {dateFns.format(pickedTime, 'dddd, MMMM Do, YYYY')}
              </p>

              <p>
                <ClockIcon /> {dateFns.format(pickedTime, 'HH:mm')}
              </p>

              {!isDone &&
                <div className="inputs-container">
                  <FormControl required error={userNameError}>
                    <TextField
                      sx={{
                        marginRight: "5px"
                      }}
                      margin="dense"
                      className="name-input"
                      id="name"
                      name="name"
                      required
                      error={userNameError}
                      value={userName}
                      onChange={(e) => { setUserName(e.target.value); setUserNameError(false); setGeneralErr(false) }}
                      label="Your Name"
                      variant="filled"
                    />
                    {/* {userNameError && (
                      <FormHelperText error><FailedIcon /> Name is required</FormHelperText>
                    )} */}
                  </FormControl>
                  <FormControl error={userEmailError} required>
                    <TextField
                      sx={{
                        marginLeft: "5px"
                      }}
                      margin="dense"
                      className="email-input"
                      id="email"
                      name="email"
                      value={userEmail}
                      error={userEmailError}
                      required
                      onChange={(e) => { setUserEmail(e.target.value); setUserEmailError(false); setGeneralErr(false) }}
                      label="Email Address"
                      variant="filled"
                    />
                    {userEmailError && (
                      <FormHelperText error><FailedIcon /> Valid Email is required</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl>
                    <TextField
                      sx={{
                        marginRight: "5px"
                      }}
                      margin="dense"
                      className="phone-input"
                      id="phone"
                      name="phone"
                      required
                      value={userPhone}
                      error={userPhoneError}
                      onChange={(e) => { setUserPhone(e.target.value); setUserPhoneError(false); setGeneralErr(false) }}
                      label="Phone Number"
                      variant="filled"
                    />
                    {/* {userPhoneError && (
                      <FormHelperText error><FailedIcon /> Phone number is required</FormHelperText>
                    )} */}
                  </FormControl>
                  <FormControl>
                    <TextField
                      sx={{
                        marginLeft: "5px"
                      }}
                      variant="filled"
                      margin="dense"
                      className="address-input"
                      id="address"
                      value={userAddress}
                      error={userAddressError}
                      name="address"
                      required
                      onChange={(e) => { setUserAddress(e.target.value); setUserAddressError(false); setGeneralErr(false) }}
                      label="Address"
                    />
                    {/* {userAddressError && (
                      <FormHelperText error><FailedIcon /> Address is required</FormHelperText>
                    )} */}
                  </FormControl>
                  <FormControl
                    sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    <TextField
                      sx={{
                        width: "95%",
                      }}
                      className="problem-description-input"
                      id="title"
                      name="title"
                      required
                      value={eventTitle}
                      error={eventTitleError}
                      onChange={(e) => { setEventTitle(e.target.value); setEventTitleError(false); setGeneralErr(false) }}
                      label="Describe your tech issue here..."
                      variant="filled"
                    />
                    {/* {eventTitleError && (
                      <FormHelperText error><FailedIcon /> Please provide details about your tech issue</FormHelperText>
                    )} */}
                  </FormControl>
                </div>
              }

              {!isDone && (
                <p>
                  <button className='goBackButton' disabled={isLoading} onClick={handleCloseConfirm}>
                    Go Back
                  </button>
                </p>
              )}
            </PopupHeader>

            {!isDone ? (
              <ConfirmButton disabled={isLoading} onClick={handleConfirm}>
                {isLoading ? loadingText : confirmText}
              </ConfirmButton>
            ) : doneText ? (
              <Success>
                <p>
                  <SuccessIcon /> {doneText}
                </p>
              </Success>
            ) : null}

            {generalErr && (
              <Failed>
                <p>
                  <FailedIcon /> {generalErr}
                </p>
              </Failed>
            )}

            {err && (
              <Failed>
                <p>
                  <FailedIcon /> {err}
                </p>
              </Failed>
            )}
          </Popup>
        )}
      </PopupWrapper>
    </ThemeProvider>
  );
}

DayTimePicker.propTypes = {
  timeSlotValidator: PropTypes.func,
  timeSlotSizeMinutes: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDone: PropTypes.bool.isRequired,
  err: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  loadingText: PropTypes.string,
  doneText: PropTypes.string,
  theme: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
    background: PropTypes.string,
    buttons: PropTypes.shape({
      disabled: PropTypes.shape({
        color: PropTypes.string,
        background: PropTypes.string
      }),
      confirm: PropTypes.shape({
        color: PropTypes.string,
        background: PropTypes.string,
        hover: PropTypes.shape({
          color: PropTypes.string,
          background: PropTypes.string
        })
      })
    })
  })
};

DayTimePicker.defaultProps = {
  confirmText: 'Schedule',
  loadingText: 'Scheduling..',
  doneText: 'Your event has been scheduled!',
  theme: {
    primary: '#3a9ad9',
    secondary: '#f0f0f0',
    background: '#fff',
    buttons: {
      disabled: {
        color: '#333',
        background: '#dfdfdf'
      },
      confirm: {
        color: '#fff',
        background: '#3a9ad9',
        hover: {
          color: '',
          background: '#3a9ad9d6'
        }
      }
    },
    feedback: {
      success: {
        color: '#29aba4'
      },
      failed: {
        color: '#eb7260'
      }
    }
  }
};

export default DayTimePicker;
