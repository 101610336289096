import React from 'react'
import { scroller } from 'react-scroll';
import './Computers.css'
import logo from '../../images/logo2.png'



function NewHome() {

  return (
    <div className='computerContainer' >
        <div className='computerBody'>
            <a href='/' style={{textDecoration:'none'}}>
            <div className='computerLogoContainer'>
                <img className='logoImage' src={logo}/>
                <h5 className='computerLogoHeadline'>iLand Tech <br/> Martha's Vineyard</h5>
            </div>
            </a>
            <div className='modalWindow'>
                <h1 className='modalHeading'>Computers</h1>
                <div className='modalNavbarContainer'>
                <a href='/'><button className='navbar-button'>Main</button></a>
                <a href='/wifi'><button className='navbar-button'>WiFi</button></a>
                <a href='/smartHome'><button className='navbar-button'>Automation</button></a>
                <a href='/cameras'><button className='navbar-button'>Cameras</button></a>
                <a href='/av'><button className='navbar-button'>AV</button></a>
                <a href='/cloud'><button className='navbar-button'>Cloud</button></a>
                </div>
                <p className='computerModalParagraph'>Unlock the full potential of your devices with our computer services. From troubleshooting and maintenance to software optimization, our expert technicians ensure your computers run smoothly. Whether you're a business or an individual, we provide tailored solutions to enhance performance, security, and overall computing experience. Trust iLand Tech for reliable, efficient, and personalized computer services that keep you ahead in the digital world.</p>

               
            </div>
        </div>
    </div>
  )
}

export default NewHome