import styledEngine from '@mui/styled-engine';
import React from 'react';
import styled from 'styled-components';
import Service from '../Components/Service';
import islandrunner from '../images/islandrunner.png';
import alexCarDetailing from '../images/alexCarDetailing.png'
import brodskyWoodWorking from '../images/BrodskyWoodWorking.png'
import mvChamberOfCommerce from '../images/mvChamberOfCommerce.webp'
import cameraLogo from '../images/security-camera.png';
import smartHome from '../images/smart-home.png';
import computerLogo from '../images/computer-logo.png';
import BgImg from '../images/services-background.jpeg';
import {AiOutlineColumnWidth} from 'react-icons/ai'
import Footer from '../Components/Footer';

export const PageContainer = styled.div`
width:100vw;
// border-top:2px solid;
border-color:#007791;

@media screen and (max-width: 650px){
  border-top:none;
}

`

export const PageBody =styled.div`
width:100%;
height:100%;
// background:blue;
display:grid;
overflow-wrap: anywhere
margin-top:10px;
// background-image: url(${BgImg});
// background-size:cover;
// background-position:center;
`
export const BodyHeader=styled.div`
margin-top:2rem;
width:100%;
height:80%;
display:flex;
flex-direction: column;
// background:red;
justify-content:center;
align-items:center;
text-align:center;
color:black;

`

export const ServicesContainer=styled.div`
width:100%;
height:100%;
display:flex;
flex-wrap: wrap;
justify-content: space-between; 
backround:yellow;
// overflow:auto;
margin-top:2rem;
margin-bottom:2rem;
padding-bottom:5rem;

color:black;



@media screen and (max-width: 650px){
    display:inline-block;
    justify-content:center;
    padding:1px;
  }
`



function Services() {

 

  return (
    <PageContainer>
        <PageBody>
            <BodyHeader className='serviceContainer'>
                <h5 style={{color:"black"}} className="font-face-gm">Our Island Partners</h5>
                {/* <h6>Internet <AiOutlineColumnWidth /> Computers <AiOutlineColumnWidth />  Phones <AiOutlineColumnWidth /> Television <AiOutlineColumnWidth /></h6> */}
                
            </BodyHeader>

            <ServicesContainer>
                <Service url='https://islandrunnercourier.com' title="Island Runner" img = {islandrunner} alt='islandrunner' />
                <Service url='https://acd-mv.com' title="Alex's Car Detailing" img= {alexCarDetailing} alt='alexsCarDetailing' />
                <Service url='https://brodskywoodworking.com' title="Brodsky Woodworking Inc." img= {brodskyWoodWorking} alt='brodskyWoodworkingInc' />
                <Service url='https://mvy.com' title="MV Chamber of Commerce" img= {mvChamberOfCommerce} alt='brodskyWoodworkingInc' />


            </ServicesContainer>
        </PageBody>
    </PageContainer>
  )
}

export default Services