import React from 'react'
import { scroller } from 'react-scroll';
import './About.css'
import logo from '../../images/logo.png'
import photo from '../../images/aboutPhoto.png'




function NewHome() {

  return (
    <div className='aboutContainer' >
        <div className='aboutBody'>
            <a href='/' style={{textDecoration:'none'}}>
            <div className='aboutLogoContainer'>
                <img className='logoImage' src={logo}/>
                <h5 className='aboutLogoHeadline'>iLand Tech <br/> Martha's Vineyard</h5>
            </div>
            </a>
            <div className='modalWindow'>
                <h1 className='modalHeading'>About Us</h1>
                <h6 className='modalHeading-2'>Bringing seamless connection</h6>
                <div className='modalNavbarContainer'>
                <a href='/'><button className='navbar-button'>Main</button></a>
                <a href='/wifi'><button className='navbar-button'>WiFi</button></a>
                <a href='/cameras'><button className='navbar-button'>Cameras</button></a>
                <a href='/smartHome'><button className='navbar-button'>Automation</button></a>
                <a href='/computers'><button className='navbar-button'>Computers</button></a>
                <a href='/av'><button className='navbar-button'>AV</button></a>
                </div>
                <div className='descriptionContainer'>
                    <div className='photoContainer'>
                        <img  className='photo' src={photo}/>
                    </div>
                    <div className='textContainer'>
                        <h2 className='textContainerHeadline'>iLand Tech <br/> Martha's Vineyard</h2>
                        <h3 className='textContainerDescription'>We are a tech service business on the island of Martha's Vineyard. We provide services such as: Wifi/Network setup,
                            Security Camera installation, Audio/Visual setup, Smart Home automation {'('}Light control, Voice assistants, Motorized Windows etc.{')'} and other general tech/computer related issues.
                        </h3>
                        <h3 className='textContainerDescription2'>At iLand Tech, we use the latest equipments on the market to make sure that our customers get the best experience with their tech.
                        To keep our standards always at the top, we are frequently upgrading our tech and test it on our end before offering it to our customers. 
                        </h3>
                    </div>
                </div>               
            </div>
            
            
        </div>
    </div>
  )
}

export default NewHome