import React from 'react';
import Logo from '../images/logo3.png';


function email() {
  return (
    <div style={{position:"absolute", width:"100%",height:"100%",display:"flex", justifyContent:"center", background:"black"}}>
        <div className='logo'>
            <img src='../images/logo3.png'/>
        </div>
    </div>
  )
}

export default email