import React, { useEffect, useState } from 'react';
import { database } from '../firebase';
import { onValue, ref, update, push, remove } from 'firebase/database';
import { Helmet } from 'react-helmet';

const AdminDashboard = () => {
    const [bookingRequests, setBookingRequests] = useState([]);
    const [daysOff, setDaysOff] = useState([]);
    const [addDayOffStatus, setAddDayOffStatus] = useState('');
    const [selectedDate, setSelectedDate] = useState({ year: '', month: '', day: '' });

    const approveBooking = (eventId) => {
        const eventRef = ref(database, `events/${eventId}`);
        update(eventRef, { approved: "true" });
    };

    const rejectBooking = (eventId) => {
        const eventRef = ref(database, `events/${eventId}`);
        update(eventRef, { approved: "false" });
    };

    const deleteDayOff = async (dayOffId) => {
        const dayOffRef = ref(database, `days_off/${dayOffId}`);
        await remove(dayOffRef);
    };

    const generateDayOptions = () => {
        const daysInMonth = new Date(
            parseInt(selectedDate.year),
            parseInt(selectedDate.month),
            0
        ).getDate();

        return Array.from({ length: daysInMonth }, (_, i) => i + 1);
    };

    const addDayOff = () => {
        try {
            if (!selectedDate.year || !selectedDate.month || !selectedDate.day) {
                setAddDayOffStatus("You should select a date first!");
                return;
            }
            const daysOffRef = ref(database, "days_off");
            push(daysOffRef, selectedDate);
            setAddDayOffStatus("Day off added!");
        } catch (e) {
            setAddDayOffStatus(e);
            console.error(e);
        }
    };

    // Fetch booking requests
    const fetchData = async () => {
        const bookingRequestsRef = ref(database, 'events');
        onValue(bookingRequestsRef, (snapshot) => {
            const data = snapshot.val();
            const bookingRequestsArray = [];
            for (let key in data) {
                bookingRequestsArray.push({ id: key, ...data[key] });
            }
            setBookingRequests(bookingRequestsArray);
        });
    };

    const fetchDaysOff = async () => {
        const daysOffRef = ref(database, 'days_off');
        onValue(daysOffRef, (snapshot) => {
            const data = snapshot.val();
            const daysOffArray = [];
            for (let key in data) {
                daysOffArray.push({ id: key, ...data[key] });
            }
            setDaysOff(daysOffArray);
        });
    };

    useEffect(() => {
        fetchData();
        fetchDaysOff();
    }, []);

    const timeOptions = {
        timeZone: 'America/New_York',
        timeZoneName: 'short',
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };

    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    return (
        
        <div>
            <Helmet>
                 <meta name="robots" content="noindex" />
             </Helmet>
            <h1>Admin Dashboard</h1>
            <hr />
            <h2>Events</h2>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Time</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {bookingRequests.map((request) => (
                        <tr key={request.id}>
                            <td>{request.userName}</td>
                            <td>{request.userEmail}</td>
                            <td>{request.userPhone}</td>
                            <td>{new Date(request.dateTime).toLocaleString('en-US', timeOptions)}</td>
                            <td>{request.eventDescription}</td>
                            <td>
                                {request.approved === "true"
                                    ? "Approved"
                                    : request.approved === "false"
                                        ? "Rejected"
                                        : "Pending"}
                            </td>
                            <td>
                                {request.approved === "pending" && (
                                    <>
                                        <button onClick={() => approveBooking(request.id)}>Approve</button>
                                        <button onClick={() => rejectBooking(request.id)}>Reject</button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <hr />
            <h2>Days Off</h2>
            <div>
                <select
                    value={selectedDate.year}
                    onChange={e =>
                        setSelectedDate({ ...selectedDate, year: e.target.value })
                    }
                >
                    <option value="">Year</option>
                    {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() + i).map(year => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>

                <select
                    value={selectedDate.month}
                    onChange={e =>
                        setSelectedDate({ ...selectedDate, month: e.target.value })
                    }
                >
                    <option value="">Month</option>
                    {monthNames.map((monthName, index) => (
                        <option key={index + 1} value={index + 1}>
                            {monthName}
                        </option>
                    ))}
                </select>


                <select
                    value={selectedDate.day}
                    onChange={e =>
                        setSelectedDate({ ...selectedDate, day: e.target.value })
                    }
                >
                    <option value="">Day</option>
                    {generateDayOptions().map(day => (
                        <option key={day} value={day}>
                            {day}
                        </option>
                    ))}
                </select>

                <button onClick={addDayOff}>Add</button>
                <p>{addDayOffStatus}</p>
                <br />
                <ul>
                    {daysOff.map((dayOff, index) => (
                        <li key={dayOff.key}>
                            {dayOff.year}-{('0' + dayOff.month).slice(-2)}-{('0' + dayOff.day).slice(-2)}
                            <button style={{ marginLeft: '8px' }} onClick={() => { deleteDayOff(dayOff.id); setAddDayOffStatus("Day off deleted!") }}>x</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};


export default AdminDashboard;
