export default function extend(scheduler) {
  var generateStringToDate = function generateStringToDate(format, utc) {
    var splt = "var temp=date.match(/[a-zA-Z]+|[0-9]+/g);";
    var mask = format.match(/%[a-zA-Z]/g);

    for (var i = 0; i < mask.length; i++) {
      switch (mask[i]) {
        case "%j":
        case "%d":
          splt += "set[2]=temp[" + i + "]||1;";
          break;

        case "%n":
        case "%m":
          splt += "set[1]=(temp[" + i + "]||1)-1;";
          break;

        case "%y":
          splt += "set[0]=temp[" + i + "]*1+(temp[" + i + "]>50?1900:2000);";
          break;

        case "%g":
        case "%G":
        case "%h":
        case "%H":
          splt += "set[3]=temp[" + i + "]||0;";
          break;

        case "%i":
          splt += "set[4]=temp[" + i + "]||0;";
          break;

        case "%Y":
          splt += "set[0]=temp[" + i + "]||0;";
          break;

        case "%a":
        case "%A":
          splt += "set[3]=set[3]%12+((temp[" + i + "]||'').toLowerCase()=='am'?0:12);";
          break;

        case "%s":
          splt += "set[5]=temp[" + i + "]||0;";
          break;

        case "%M":
          splt += "set[1]=this.locale.date.month_short_hash[temp[" + i + "]]||0;";
          break;

        case "%F":
          splt += "set[1]=this.locale.date.month_full_hash[temp[" + i + "]]||0;";
          break;

        default:
          break;
      }
    }

    var code = "set[0],set[1],set[2],set[3],set[4],set[5]";
    if (utc) code = " Date.UTC(" + code + ")";
    return new Function("date", "var set=[0,0,1,0,0,0]; " + splt + " return new Date(" + code + ");");
  };

  var csp_date_to_str = function csp_date_to_str(format, utc) {
    return function (date) {
      return format.replace(/%[a-zA-Z]/g, function (a) {
        switch (a) {
          case "%d":
            return utc ? scheduler.date.to_fixed(date.getUTCDate()) : scheduler.date.to_fixed(date.getDate());

          case "%m":
            return utc ? scheduler.date.to_fixed(date.getUTCMonth() + 1) : scheduler.date.to_fixed(date.getMonth() + 1);

          case "%j":
            return utc ? date.getUTCDate() : date.getDate();

          case "%n":
            return utc ? date.getUTCMonth() + 1 : date.getMonth() + 1;

          case "%y":
            return utc ? scheduler.date.to_fixed(date.getUTCFullYear() % 100) : scheduler.date.to_fixed(date.getFullYear() % 100);

          case "%Y":
            return utc ? date.getUTCFullYear() : date.getFullYear();

          case "%D":
            return utc ? scheduler.locale.date.day_short[date.getUTCDay()] : scheduler.locale.date.day_short[date.getDay()];

          case "%l":
            return utc ? scheduler.locale.date.day_full[date.getUTCDay()] : scheduler.locale.date.day_full[date.getDay()];

          case "%M":
            return utc ? scheduler.locale.date.month_short[date.getUTCMonth()] : scheduler.locale.date.month_short[date.getMonth()];

          case "%F":
            return utc ? scheduler.locale.date.month_full[date.getUTCMonth()] : scheduler.locale.date.month_full[date.getMonth()];

          case "%h":
            return utc ? scheduler.date.to_fixed((date.getUTCHours() + 11) % 12 + 1) : scheduler.date.to_fixed((date.getHours() + 11) % 12 + 1);

          case "%g":
            return utc ? (date.getUTCHours() + 11) % 12 + 1 : (date.getHours() + 11) % 12 + 1;

          case "%G":
            return utc ? date.getUTCHours() : date.getHours();

          case "%H":
            return utc ? scheduler.date.to_fixed(date.getUTCHours()) : scheduler.date.to_fixed(date.getHours());

          case "%i":
            return utc ? scheduler.date.to_fixed(date.getUTCMinutes()) : scheduler.date.to_fixed(date.getMinutes());

          case "%a":
            return utc ? date.getUTCHours() > 11 ? "pm" : "am" : date.getHours() > 11 ? "pm" : "am";

          case "%A":
            return utc ? date.getUTCHours() > 11 ? "PM" : "AM" : date.getHours() > 11 ? "PM" : "AM";

          case "%s":
            return utc ? scheduler.date.to_fixed(date.getUTCSeconds()) : scheduler.date.to_fixed(date.getSeconds());

          case "%W":
            return utc ? scheduler.date.to_fixed(scheduler.date.getUTCISOWeek(date)) : scheduler.date.to_fixed(scheduler.date.getISOWeek(date));

          default:
            return a;
        }
      });
    };
  };

  var csp_str_to_date = function csp_str_to_date(format, utc) {
    return function (date) {
      var set = [0, 0, 1, 0, 0, 0];
      var temp = date.match(/[a-zA-Z]+|[0-9]+/g);
      var mask = format.match(/%[a-zA-Z]/g);

      for (var i = 0; i < mask.length; i++) {
        switch (mask[i]) {
          case "%j":
          case "%d":
            set[2] = temp[i] || 1;
            break;

          case "%n":
          case "%m":
            set[1] = (temp[i] || 1) - 1;
            break;

          case "%y":
            set[0] = temp[i] * 1 + (temp[i] > 50 ? 1900 : 2000);
            break;

          case "%g":
          case "%G":
          case "%h":
          case "%H":
            set[3] = temp[i] || 0;
            break;

          case "%i":
            set[4] = temp[i] || 0;
            break;

          case "%Y":
            set[0] = temp[i] || 0;
            break;

          case "%a":
          case "%A":
            set[3] = set[3] % 12 + ((temp[i] || '').toLowerCase() == 'am' ? 0 : 12);
            break;

          case "%s":
            set[5] = temp[i] || 0;
            break;

          case "%M":
            set[1] = scheduler.locale.date.month_short_hash[temp[i]] || 0;
            break;

          case "%F":
            set[1] = scheduler.locale.date.month_full_hash[temp[i]] || 0;
            break;

          default:
            break;
        }
      }

      if (utc) {
        return new Date(Date.UTC(set[0], set[1], set[2], set[3], set[4], set[5]));
      }

      return new Date(set[0], set[1], set[2], set[3], set[4], set[5]);
    };
  };

  var canUseCsp = false;

  (function () {
    try {
      new Function("canUseCsp = false;");
    } catch (e) {
      canUseCsp = true;
    }
  })();

  function useCsp() {
    var result = false;

    if (scheduler.config.csp === "auto") {
      result = canUseCsp;
    } else {
      result = scheduler.config.csp;
    }

    return result;
  }

  scheduler.date = {
    init: function init() {
      var s = scheduler.locale.date.month_short;
      var t = scheduler.locale.date.month_short_hash = {};

      for (var i = 0; i < s.length; i++) {
        t[s[i]] = i;
      }

      var s = scheduler.locale.date.month_full;
      var t = scheduler.locale.date.month_full_hash = {};

      for (var i = 0; i < s.length; i++) {
        t[s[i]] = i;
      }
    },
    _bind_host_object: function _bind_host_object(method) {
      if (method.bind) {
        return method.bind(scheduler);
      } else {
        return function () {
          return method.apply(scheduler, arguments);
        };
      }
    },
    date_part: function date_part(date) {
      var old = new Date(date);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      if (date.getHours() && ( //shift to yesterday on dst
      date.getDate() < old.getDate() || date.getMonth() < old.getMonth() || date.getFullYear() < old.getFullYear())) date.setTime(date.getTime() + 60 * 60 * 1000 * (24 - date.getHours()));
      return date;
    },
    time_part: function time_part(date) {
      return (date.valueOf() / 1000 - date.getTimezoneOffset() * 60) % 86400;
    },
    week_start: function week_start(date) {
      var shift = date.getDay();

      if (scheduler.config.start_on_monday) {
        if (shift === 0) shift = 6;else shift--;
      }

      return this.date_part(this.add(date, -1 * shift, "day"));
    },
    month_start: function month_start(date) {
      date.setDate(1);
      return this.date_part(date);
    },
    year_start: function year_start(date) {
      date.setMonth(0);
      return this.month_start(date);
    },
    day_start: function day_start(date) {
      return this.date_part(date);
    },
    _add_days: function _add_days(date, inc) {
      var ndate = new Date(date.valueOf());
      ndate.setDate(ndate.getDate() + inc); // Workaround for Safari/iOS timezone bug, ref:OKZ-149693

      if (inc == Math.round(inc) && inc > 0) {
        var datesDiff = +ndate - +date,
            rest = datesDiff % (24 * 60 * 60 * 1000);

        if (rest && date.getTimezoneOffset() == ndate.getTimezoneOffset()) {
          var hours = rest / (60 * 60 * 1000);
          ndate.setTime(ndate.getTime() + (24 - hours) * 60 * 60 * 1000);
        }
      }

      if (inc >= 0 && !date.getHours() && ndate.getHours() && ( //shift to yesterday on dst
      ndate.getDate() < date.getDate() || ndate.getMonth() < date.getMonth() || ndate.getFullYear() < date.getFullYear())) ndate.setTime(ndate.getTime() + 60 * 60 * 1000 * (24 - ndate.getHours()));
      return ndate;
    },
    add: function add(date, inc, mode) {
      var ndate = new Date(date.valueOf());

      switch (mode) {
        case "day":
          ndate = scheduler.date._add_days(ndate, inc);
          break;

        case "week":
          ndate = scheduler.date._add_days(ndate, inc * 7);
          break;

        case "month":
          ndate.setMonth(ndate.getMonth() + inc);
          break;

        case "year":
          ndate.setYear(ndate.getFullYear() + inc);
          break;

        case "hour":
          /*
           setHour(getHour() + inc) and setMinutes gives weird result when is applied on a Daylight Saving time switch
           setTime seems working as expected
          */
          ndate.setTime(ndate.getTime() + inc * 60 * 60 * 1000);
          break;

        case "minute":
          ndate.setTime(ndate.getTime() + inc * 60 * 1000);
          break;

        default:
          return scheduler.date["add_" + mode](date, inc, mode);
      }

      return ndate;
    },
    to_fixed: function to_fixed(num) {
      if (num < 10) return "0" + num;
      return num;
    },
    copy: function copy(date) {
      return new Date(date.valueOf());
    },
    date_to_str: function date_to_str(format, utc) {
      if (useCsp()) {
        return csp_date_to_str(format, utc);
      }

      format = format.replace(/%[a-zA-Z]/g, function (a) {
        switch (a) {
          case "%d":
            return "\"+this.date.to_fixed(date.getDate())+\"";

          case "%m":
            return "\"+this.date.to_fixed((date.getMonth()+1))+\"";

          case "%j":
            return "\"+date.getDate()+\"";

          case "%n":
            return "\"+(date.getMonth()+1)+\"";

          case "%y":
            return "\"+this.date.to_fixed(date.getFullYear()%100)+\"";

          case "%Y":
            return "\"+date.getFullYear()+\"";

          case "%D":
            return "\"+this.locale.date.day_short[date.getDay()]+\"";

          case "%l":
            return "\"+this.locale.date.day_full[date.getDay()]+\"";

          case "%M":
            return "\"+this.locale.date.month_short[date.getMonth()]+\"";

          case "%F":
            return "\"+this.locale.date.month_full[date.getMonth()]+\"";

          case "%h":
            return "\"+this.date.to_fixed((date.getHours()+11)%12+1)+\"";

          case "%g":
            return "\"+((date.getHours()+11)%12+1)+\"";

          case "%G":
            return "\"+date.getHours()+\"";

          case "%H":
            return "\"+this.date.to_fixed(date.getHours())+\"";

          case "%i":
            return "\"+this.date.to_fixed(date.getMinutes())+\"";

          case "%a":
            return "\"+(date.getHours()>11?\"pm\":\"am\")+\"";

          case "%A":
            return "\"+(date.getHours()>11?\"PM\":\"AM\")+\"";

          case "%s":
            return "\"+this.date.to_fixed(date.getSeconds())+\"";

          case "%W":
            return "\"+this.date.to_fixed(this.date.getISOWeek(date))+\"";

          default:
            return a;
        }
      });
      if (utc) format = format.replace(/date\.get/g, "date.getUTC");
      var func = new Function("date", "return \"" + format + "\";");
      return scheduler.date._bind_host_object(func);
    },
    str_to_date: function str_to_date(format, utc, exactFormat) {
      var stringToDateMethod = useCsp() ? csp_str_to_date : generateStringToDate;
      var parseExactFormat = stringToDateMethod(format, utc); //return scheduler.date._bind_host_object(func);
      // eslint-disable-next-line

      var yyyyMMddhhIIss = /^[0-9]{4}(\-|\/)[0-9]{2}(\-|\/)[0-9]{2} ?(([0-9]{1,2}:[0-9]{1,2})(:[0-9]{1,2})?)?$/; // MM/dd/yyyy - default old format for xml-date
      // eslint-disable-next-line

      var MMddyyyyhhIIss = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4} ?(([0-9]{1,2}:[0-9]{2})(:[0-9]{1,2})?)?$/; // dd-MM-yyyy - default old format for api-date
      // eslint-disable-next-line

      var ddMMyyyyhhIIss = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} ?(([0-9]{1,2}:[0-9]{1,2})(:[0-9]{1,2})?)?$/; // eslint-disable-next-line

      var ISO8601 = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

      var isYMDDate = function isYMDDate(datestr) {
        return yyyyMMddhhIIss.test(String(datestr));
      };

      var isMDYDate = function isMDYDate(datestr) {
        return MMddyyyyhhIIss.test(String(datestr));
      };

      var isDMYDate = function isDMYDate(datestr) {
        return ddMMyyyyhhIIss.test(String(datestr));
      };

      var isISO8601 = function isISO8601(datestr) {
        return ISO8601.test(datestr);
      };

      var parseYMD = stringToDateMethod("%Y-%m-%d %H:%i:%s", utc);
      var parseMDY = stringToDateMethod("%m/%d/%Y %H:%i:%s", utc);
      var parseDMY = stringToDateMethod("%d-%m-%Y %H:%i:%s", utc);
      return function (dateString) {
        if (!exactFormat && !scheduler.config.parse_exact_format) {
          if (dateString && dateString.getISOWeek) {
            return new Date(dateString);
          } else if (typeof dateString === "number") {
            return new Date(dateString);
          } else if (isYMDDate(dateString)) {
            return parseYMD(dateString);
          } else if (isMDYDate(dateString)) {
            return parseMDY(dateString);
          } else if (isDMYDate(dateString)) {
            return parseDMY(dateString);
          } else if (isISO8601(dateString)) {
            return new Date(dateString);
          }
        }

        return parseExactFormat.call(scheduler, dateString);
      };
    },
    getISOWeek: function getISOWeek(ndate) {
      if (!ndate) return false;
      ndate = this.date_part(new Date(ndate));
      var nday = ndate.getDay();

      if (nday === 0) {
        nday = 7;
      }

      var first_thursday = new Date(ndate.valueOf());
      first_thursday.setDate(ndate.getDate() + (4 - nday));
      var year_number = first_thursday.getFullYear(); // year of the first Thursday

      var ordinal_date = Math.round((first_thursday.getTime() - new Date(year_number, 0, 1).getTime()) / 86400000); //ordinal date of the first Thursday - 1 (so not really ordinal date)

      var week_number = 1 + Math.floor(ordinal_date / 7);
      return week_number;
    },
    getUTCISOWeek: function getUTCISOWeek(ndate) {
      return this.getISOWeek(this.convert_to_utc(ndate));
    },
    convert_to_utc: function convert_to_utc(date) {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }
  };
}