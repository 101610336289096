import './App.css';
import Home from './Pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Services from './Pages/Services';
import Partners from './Pages/Partners'
import Footer from './Components/Footer';
import SchedulerPage from './Pages/SchedulerPage';
import AdminDashboard from './Components/AdminDashboard';
import Calendar from './Components/Calendar';
import Service2 from './Components/Service2';
import Plans from './Pages/Plans';
import NewHome from './Pages/NewHome/NewHome';
import GoogleReviews from './Components/GoogleReviews';


function App() {


  return (

    <div style={{ height: "100%" }}>
      {/* <Home /> */}
      <NewHome/>
      <Services />
      <Plans/>
      <Partners />
      {/* <GoogleReviews/> */}
      {/* <Service2/> */}
      {/* <SchedulerPage /> */}
      <Footer />
    </div>
  );
}

export default App;
