import React from 'react'
import { scroller } from 'react-scroll';
import './Cloud.css'
import logo from '../../images/logo2.png'



function NewHome() {

  return (
    <div className='cloudContainer' >
        <div className='cloudBody'>
            <a href='/' style={{textDecoration:'none'}}>
            <div className='cloudLogoContainer'>
                <img className='logoImage' src={logo}/>
                <h5 className='cameraLogoHeadline'>iLand Tech <br/> Martha's Vineyard</h5>
            </div>
            </a>
            <div className='modalWindow'>
                <h1 className='modalHeading'>Cloud Services</h1>
                <div className='modalNavbarContainer'>
                <a href='/'><button className='navbar-button'>Main</button></a>
                <a href='/wifi'><button className='navbar-button'>WiFi</button></a>
                <a href='/smartHome'><button className='navbar-button'>Automation</button></a>
                <a href='/computers'><button className='navbar-button'>Computers</button></a>
                <a href='/cameras'><button className='navbar-button'>Cameras</button></a>
                <a href='/av'><button className='navbar-button'>AV</button></a>
                </div>
                <p className='cloudModalParagraph'>Unleash the full potential of your business with Our Cloud Services. Our robust, secure, and scalable cloud solutions redefine efficiency. From dynamic application hosting to seamless data storage, we optimize your operations with cutting-edge technology.</p>
                <p className='cloudModalParagraph'>Additionally, Our Cloud Services empowers your business with Internet of Things (IoT) connectivity, enabling seamless management and monitoring of your devices. Stay ahead in the digital landscape as our cloud platform provides the foundation for efficient IoT device interactions, ensuring real-time insights and control. </p>

               
            </div>
        </div>
    </div>
  )
}

export default NewHome