import React from 'react';
import styled from 'styled-components';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import { database } from "../firebase";
import { onValue, ref, push } from "firebase/database";
import { useState } from 'react';

const PageContainer= styled.div`
background-color:#FFF;
`

const FooterContainer = styled.footer`
  // background-color: #f8f8f8;
background-color: #FFF;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  `

const SubscribeForm = styled.form`
  display: flex;
  align-items: center;
  background-color: #FFF;
  padding: 5px;
  border-radius: 0px;
  margin-left:1rem;

  @media screen and (max-width: 650px){
  }


`;

const SubscribeInput = styled.input`
  border: none;
  background-color: #f1f1f1;
  margin-right: 10px;
  padding: 8px;
  width: 200px;

  &:focus {
    outline: none;
  }
`;

const SubscribeButton = styled.button`
  border: none;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;

`;

const DeveloperInfo = styled.div`
  font-size: 15px;
  // margin-left:-12%;

  @media screen and (max-width: 650px) {
    margin-left:1px;

  }
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const SocialMediaLink = styled.a`
  color: #000;
  font-size: 20px;
  &:hover {
    color: #555;
  }
`;


function Footer() {
  const [userEmail, setUserEmail] = useState('');

  const email = userEmail;
  const handleSubscribe = (e) => {
    const newSubscriber = {userEmail:email};
    e.preventDefault();
    console.log(email);
    const eventsRef = ref(database, "subscribers");
            push(eventsRef,newSubscriber);
            alert('You have successfully subscribed iLand Tech\n you will receive updates about our new technologies. ')


  };

  return (
    <PageContainer >
     {/* <SubscribeForm onSubmit={handleSubscribe}>
     <SubscribeInput type="email" onChange={(e) => setUserEmail(e.target.value)} placeholder="Subscribe us..." />
     <SubscribeButton type="submit">
       <span role="img" aria-label="Subscribe">
         📩
       </span>
     </SubscribeButton>
   </SubscribeForm> */}
    <FooterContainer>
     

      <DeveloperInfo>
        Developed by iLand Tech &copy; {new Date().getFullYear()}
      </DeveloperInfo>

      <SocialMediaContainer>
        <SocialMediaLink href="https://www.facebook.com/ilandtechmv" target="_blank">
          <AiFillFacebook />
        </SocialMediaLink>
        <SocialMediaLink href="https://www.instagram.com/ilandtechmv/?hl=en" target="_blank">
          <AiFillInstagram />
        </SocialMediaLink>
        <SocialMediaLink href="https://www.linkedin.com/in/your-profile" target="_blank">
          <AiFillLinkedin />
        </SocialMediaLink>
      </SocialMediaContainer>
    </FooterContainer>
    
   </PageContainer>
  );
}

export default Footer;
