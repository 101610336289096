import React from 'react'
import { scroller } from 'react-scroll';
import './Wifi.css'
import logo from '../../images/logo2.png'
import { useState, useRef} from 'react';
import { useEffect } from 'react';
import networkingVideo from '../../images/Networking.mp4'



function NewHome() {

  const videoRef = useRef(null);


  return (
    <div className='wifiContainer' >
       <video
      className='video'
      style={{objectFit:"cover"}}
      // style={{width:'100%', height:'70%',}}
      ref={videoRef}
      playsInline
      webkit-playsinline="true"
       autoPlay 
       loop
       muted
        id='video'
        >
        <source src={networkingVideo} type='video/mp4'/>
      </video>
        <div className='wifiBody'>
            <a href='/' style={{textDecoration:'none'}}>
            <div className='wifiLogoContainer'>
                <img className='logoImage' src={logo}/>
                <h5 className='wifiLogoHeadline'>iLand Tech <br/> Martha's Vineyard</h5>
            </div>
            </a>
            <div className='modalWindow'>
                <h1 className='modalHeading'>Wifi / Networking</h1>
                <div className='modalNavbarContainer'>
                <a href='/'><button className='navbar-button'>Main</button></a>
                <a href='/cameras'><button className='navbar-button'>Cameras</button></a>
                <a href='/smartHome'><button className='navbar-button'>Homes</button></a>
                <a href='/computers'><button className='navbar-button'>Computers</button></a>
                <a href='/av'><button className='navbar-button'>AV</button></a>
                <a href='/cloud'><button className='navbar-button'>Cloud</button></a>
                </div>
                <p className='wifiModalParagraph'>We specialize in delivering top-tier WiFi and networking solutions. Whether you're in need of a new system installation or troubleshooting for existing networks, our expert technicians are dedicated to providing seamless connectivity. Experience the epitome of reliability with our cutting-edge installations, and rest easy knowing our skilled team is ready to swiftly troubleshoot and resolve any connectivity issues. Choose iLand Tech for unparalleled expertise, tailored solutions, and a commitment to keeping you seamlessly connected.</p>
                <p className='wifiModalParagraph2'>From robust hardware selection to meticulous cable management, we prioritize every detail to guarantee a high-performance system. When it comes to troubleshooting, our technicians leverage advanced diagnostic tools and methodologies to pinpoint and resolve issues efficiently. Count on us for a comprehensive approach that combines technical expertise with a commitment to delivering unparalleled networking solutions.</p>

               
            </div>
        </div>
    </div>
  )
}

export default NewHome