import React from 'react'
import { scroller } from 'react-scroll';
import './Cameras.css'
import logo from '../../images/logo2.png'
import { useState, useRef} from 'react';
import { useEffect } from 'react';
import cameraBackgroundVideo from '../../images/cameraBackgroundVideo.mp4'



function NewHome() {

  const videoRef = useRef(null);

  return (
    <div className='cameraContainer' >
      <video
      className='video'
      style={{objectFit:"cover"}}
      // style={{width:'100%', height:'70%',}}
      ref={videoRef}
      playsInline
      webkit-playsinline="true"
       autoPlay 
       loop
       muted
        id='video'
        >
        <source src={cameraBackgroundVideo} type='video/mp4'/>
      </video>
        <div className='cameraBody'>
            <a href='/' style={{textDecoration:'none'}}>
            <div className='cameraLogoContainer'>
                <img className='logoImage' src={logo}/>
                <h5 className='cameraLogoHeadline'>iLand Tech <br/> Martha's Vineyard</h5>
            </div>
            </a>
            <div className='modalWindow'>
                <h1 className='modalHeading'>Security Cameras</h1>
                <div className='modalNavbarContainer'>
                <a href='/'><button className='navbar-button'>Main</button></a>
                <a href='/wifi'><button className='navbar-button'>WiFi</button></a>
                <a href='/smartHome'><button className='navbar-button'>Automation</button></a>
                <a href='/computers'><button className='navbar-button'>Computers</button></a>
                <a href='/av'><button className='navbar-button'>AV</button></a>
                <a href='/cloud'><button className='navbar-button'>Cloud</button></a>
                </div>
                <p className='cameraModalParagraph'>Explore advanced security solutions with us. Our services include seamless installation of high-quality surveillance systems tailored to your specific needs. From homes to businesses, our precision-focused approach ensures comprehensive coverage and enhanced protection. Benefit from cutting-edge technology, high-definition cameras, and smart analytics for top-tier security camera installations. Choose iLand Tech for professional, tailored security solutions that provide peace of mind for your property.</p>
                <p className='cameraModalParagraph2'>Our expertise extends to both wireless solar charging and power-based cameras, providing a versatile and sustainable approach to security. Harness the power of the sun with our solar charging cameras, offering eco-friendly and cost-effective solutions that operate independently of the grid. For areas with traditional power sources, our power-based cameras deliver reliable performance and advanced features. We empower you with cutting-edge technology, ensuring your security cameras not only meet but exceed your expectations, offering a blend of efficiency, sustainability, and superior functionality.</p>

               
            </div>
        </div>
    </div>
  )
}

export default NewHome