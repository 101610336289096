import React from 'react'
import { useState, useRef} from 'react';
import { useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import Slider from 'react-slick';
import { scroller } from 'react-scroll';
import { Button } from "antd";
import './Home.css';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand
} from 'mdb-react-ui-kit';
// import Logo from '../images/logo3.png';
import Logo from '../images/ilandTechlogo.png';
// import BgImage from '../images/new-background.png';
import BgImage2 from '../images/mobileBackground.png';
import newBackground from '../images/background.png'
import backgroundVideo1 from  '../images/giorga.mp4';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {MdLocationOn} from 'react-icons/md';
import {BiGridSmall, BiRun} from 'react-icons/bi';
import {SlSettings} from "react-icons/sl"
import {BsWrenchAdjustable} from "react-icons/bs"
import { color } from '@mui/system';
import Calendar from '../Components/Calendar';
import {AiOutlineMinusCircle} from 'react-icons/ai';
import {AiFillEye} from 'react-icons/ai';
import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import Typewriter from '../Components/Typewriter';



export const HomeContainer = styled.div`
width:100vw;
height:100vh;
display: flex;
flex-direction: column;
justify-content: center;  /* Center content vertically */
align-items: center;      /* Center content horizontally */
background-image: url(${newBackground});
background-size:cover;
// overflow: auto;

  @media screen and (max-width: 1366px) {
  background-image: url(${BgImage2});
    background-size: cover;
    background-position-x:center;

  }
  @media screen and (max-width: 912px) {
  background-image: url(${BgImage2});
    background-size: cover;
    background-position-x:center;

  }
 
  @media screen and (max-width: 650px) {
  background-image: url(${BgImage2});
    background-size: cover;
    background-position-x:center;


  }
  @media screen and (max-width: 414px) {
    background-image: url(${BgImage2});
      background-size: cover;
      background-position-x:center;

    }
  @media screen and (max-width: 412px) {
    background-image: url(${BgImage2});
      background-size: cover;
      background-position-x:center;
  
    }

 

  
  @media screen and (max-width: 375px) {
    background-image: url(${BgImage2});
      background-size:  cover;
      bacgkround-position-x:center;
  
    }
  @media screen and (max-width: 280px) {
    background-image: url(${BgImage2});
      background-size: cover;
    bacgkround-position-x:center;

    }
  
}
`
export const HeadingContainer = styled.div`
width:100%;
height:20%;

// background:red;
display:flex;
`
export const NavbarContainer = styled.div`
top:0;
right:0;
width:100%;
height:90px;
// background-color:#0c1e3fac ;
`
export const Body = styled.div`
display:block;
flex-direction: column;
justify-content: center;  /* Center content vertically */
align-items: center;      /* Center content horizontally */
// background:blue;
`

export const LogoContainer = styled.div`
// background-color:blue;
display: grid;
align-items: center;
justify-content: center;


`
const stylePlusIcon = { color: "white", fontSize: "1.5em", marginLeft: "5px", marginTop:"-2px" };
const styleRunIcon = { color: "white", marginBottom:"5px", marginRight:"5px"}

function Home() {

const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [isClicked, setIsClicked] = useState(false);
  const [isBigScreen, setIsBigScreen] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const videoRef = useRef(null);

  const sloganRef = useRef(null);
  const [isSloganVisible, setIsSloganVisible] = useState(false);

  useEffect(() => {
    const sloganOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Adjust this threshold as needed
    };

    const sloganObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsSloganVisible(true);
        } else {
          setIsSloganVisible(false);
        }
      });
    }, sloganOptions);

    if (sloganRef.current) {
      sloganObserver.observe(sloganRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (sloganRef.current) {
        sloganObserver.unobserve(sloganRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleVideoTouchEnd = () => {
    if (isVideoPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsVideoPlaying(!isVideoPlaying);
  };

  const windowSize = useRef([window.innerWidth, window.innerHeight])
  useEffect(() => {

    if (windowSize.current[0] >= 1020) {
      setIsBigScreen(true);
    }
    if (windowSize.current[0] <= 900){
      setIsPhone(true);
    }
  })


  const menuButtonHandler = () => {
    setIsClicked(!isClicked);
    setIsOpen(!isOpen);

    if (!isClicked) {
      document.body.classList.add('active-modal')
    } else {
      document.body.classList.remove('active-modal')
    }
  }

  document.addEventListener('keyup', (e) => {
    if (e.key === 'Escape' && isClicked) menuButtonHandler();
  });


  const scrollTo = (target) => {
    scroller.scrollTo(target, {
      duration: 200,
      delay: 5,
      smooth: true,
    })
  }



    

  

  function handleCallNow() {
    const phoneNumber = '5089390844'; // Replace with your desired phone number
  
    window.open(`tel:${phoneNumber}`, '_self');
  }

  return (     
    <HomeContainer className='homeContainer'>
      {/* <img id='video' src={require('https://storage.googleapis.com/ilandtech/background2.gif')} alt="loading..." /> */}
      {/* <video
      className='video'
      style={{objectFit:"cover"}}
      // style={{width:'100%', height:'70%',}}
      ref={videoRef}
      playsInline
      webkit-playsinline="true"
       autoPlay 
       loop
       muted
        id='video'
        >
        <source src={backgroundVideo1} type='video/mp4'/>
      </video> */}

      {/* <div className='highlighter'></div> */}
      
      <HeadingContainer>
      <NavbarContainer className={isScrolled ? 'navbar-fixed' : ''}>
      {/* <Button onClick={handleCallNow} style={{ background: "#113768", borderColor: "#fff" ,color:"white", fontFamily:"Raleway, sans-serif" }} className='call-button'  type="default" color='danger' shape='round' >Call Now</Button> */}
      {/* {!isOpen &&( <Button onClick={menuButtonHandler }  style={{ background: "#113768", borderColor: "#000" ,color:"white", fontFamily:"Raleway, sans-serif" }} className='appointment-button'  type="default" color='danger' shape='round' >Appointment<AiOutlinePlusCircle style={stylePlusIcon}/></Button>)} */}
      {/* {isOpen && ( <Button onClick={menuButtonHandler} style={{ background: "#113768", borderColor: "#000" ,color:"white", fontFamily:"Raleway, sans-serif" }} className='appointment-button'  type="default" color='danger' shape='round' >Appointment<AiOutlineMinusCircle style={stylePlusIcon}/></Button>)} */}
      {/* <h3 onClick={() => scrollTo('home')} ref={sloganRef}
          style={{
            position: 'absolute',
            borderColor: '#000',
            fontFamily: 'Raleway, sans-serif',
            color: isSloganVisible ? 'white' : 'white', // Change the color based on visibility
          }} className='slogan' ><h3 className="font-face-gm" >iLand Tech <img style={{height:'80px', width:'80px', marginBottom:'-30px', marginTop:'-30px'}} src={Logo}/></h3> </h3> */}
      </NavbarContainer>
     
      {/* {isClicked && isBigScreen && (<div className='modal--window'>
        <Calendar/>
        
      </div>
      )} */}
      
        
      </HeadingContainer>
      <Body>
      
      <div className='sliderStyles'>
      <Slider {...sliderSettings}>
          <div>
            <img src={BgImage2} alt="Slide 1" />
          </div>
          <div>
            <img src={BgImage2} alt="Slide 2" />
          </div>
          <div>
            <img src={BgImage2} alt="Slide 3" />
          </div>
        </Slider>
      </div>
      {/* {isClicked && isPhone && (<div className='modal--window-phone'>
        <Calendar/>
       
      </div>)} */}
      <LogoContainer className='logo-container'>
      
        <h2 className="font-face-gm"  style={{position:"absolute", top:'72%', left:'30%',color:'black'}}><Typewriter text="" delay={100} /></h2>
            {/* <img className='logo-image' src={Logo} alt="Logo" /> */}
            {/* <button onClick={() => scrollTo('serviceContainer')} style={{fontFamily:"Raleway, sans-serif"}} className='explore-button' size='large' type="primary"  shape='round' ><h5 className="font-face-gm">here...</h5></button> */}
      {/* <button onClick={() => scrollTo('serviceContainer')}  className='scrollToBottom-button'  ><h5 style={{fontSize:'25px', marginTop:'7px'}} className="font-face-gm">Services</h5></button> */}
        </LogoContainer>
       
      </Body>
      
    </HomeContainer>
  )
}

export default Home

