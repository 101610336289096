import React from 'react'
import { scroller } from 'react-scroll';
import './SmartHome.css'
import logo from '../../images/logo2.png'
import { useState, useRef} from 'react';
import { useEffect } from 'react';
import backgroundVideo1 from '../../images/smartHomeVideo.mp4'



function NewHome() {

  const videoRef = useRef(null);


  return (
    <div className='smartHomeContainer' >
      <video
      className='video'
      style={{objectFit:"cover"}}
      // style={{width:'100%', height:'70%',}}
      ref={videoRef}
      playsInline
      webkit-playsinline="true"
       autoPlay 
       loop
       muted
        id='video'
        >
        <source src={backgroundVideo1} type='video/mp4'/>
      </video>
        <div className='smartHomeBody'>
            <a href='/' style={{textDecoration:'none'}}>
            <div className='smartHomeLogoContainer'>
                <img className='logoImage' src={logo}/>
                <h5 className='smartHomeLogoHeadline'>iLand Tech <br/> Martha's Vineyard</h5>
            </div>
            </a>
            <div className='modalWindow'>
                <h1 className='modalHeading'>Smart Home Integration</h1>
                <div className='modalNavbarContainer'>
                <a href='/'><button className='navbar-button'>Main</button></a>
                <a href='/wifi'><button className='navbar-button'>WiFi</button></a>
                <a href='/cameras'><button className='navbar-button'>Cameras</button></a>
                <a href='/computers'><button className='navbar-button'>Computers</button></a>
                <a href='/av'><button className='navbar-button'>AV</button></a>
                <a href='/cloud'><button className='navbar-button'>Cloud</button></a>
                </div>
                <p className='smartHomeModalParagraph'>Our Home Automation service seamlessly integrates cutting-edge technology into your living space, creating a smart and efficient environment tailored to your lifestyle. Control lighting, temperature, security, and more with a touch or voice command, enhancing convenience and energy efficiency. Experience the future of living today with iLand Tech Home Automation – where your home responds to your needs effortlessly. Welcome to a smarter, connected life.</p>

               
            </div>
        </div>
    </div>
  )
}

export default NewHome