import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import './fonts/Croogla/Croogla4F.ttf';
import AdminDashboard from './Components/AdminDashboard';
import Email from './Pages/Email';
import About from './Pages/About/About';
import Wifi from './Pages/Wifi/Wifi';
import Cameras from './Pages/Cameras/Cameras';
import SmartHome from './Pages/SmartHome/SmartHome';
import Computers from './Pages/Computers/Computers';
import Av from './Pages/AV/Av';
import Cloud from './Pages/Cloud/Cloud';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/' element={<App/>} />
        <Route path='/admin-uruma' element={<AdminDashboard/>}/>
        <Route path='/email' element={<Email/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/wifi' element={<Wifi/>}/>
        <Route path='/cameras' element={<Cameras/>}/>
        <Route path='/smartHome' element={<SmartHome/>}/>
        <Route path='/computers' element={<Computers/>}/>
        <Route path='/av' element={<Av/>}/>
        <Route path='/cloud' element={<Cloud/>}/>
      </Routes>
    </Router>
  </React.StrictMode>
);


reportWebVitals();
