import React from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import img1 from '../images/securityCamera-Remote.png';
import img2 from '../images/meshwifi.jpeg';
import {ImConnection} from 'react-icons/im';
import {BsCloudCheckFill} from 'react-icons/bs';


import { useState,useRef } from 'react';
import { useEffect } from 'react';

import './Service2.css';











const ServiceContainer = styled.div`
  // background: blue;
  // width: 100%;
  // height: 100vh;
  // display: flex;
  // flex-direction: column;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ServiceBody = styled.div`
  background: white;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content:center;
`;

const InfoContainer = styled.div`
// background-color: rgba(128, 128, 128, 0.288);
// background-color: #003568;


width: 80%;
  height: 100%;

  @media screen and (max-width: 650px) {
    // background-color: #003568;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const DescriptionContainer = styled.div`
  width: 50%;
  height: 50%;
  // border-left:2px solid brown;


  @media screen and (max-width: 650px) {
    width: 100%;


  }
`;
const DescriptionContainerHeader = styled.div`
width:100%;
margin-top:15px;
display:flex;
justify-content:right;
margin-left:-10%;

@media screen and (max-width: 650px) {
  margin-left:-10px;
}
`

const DescriptionContainerText = styled.div`
width:80%;
float:right;
margin-top:2%;
margin-right:5%;

@media screen and (max-width: 650px) {
    margin-top:0;
    margin-right:0;
    padding:10px;
    width:100%;
  }

`

const DescriptionContainerRight = styled.div`
//   background: green;
  width: 50%;
  height: 50%;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // border-right: 2px solid lightgreen;


  @media screen and (max-width: 650px) {
    width: 100%;
    float: none;

  }
`;
const DescriptionContainerRightHeader = styled.div`
width:100%;
margin-top:10px;
display:flex;
margin-left:5%;
`

const DescriptionContainerRightText = styled.div`
width:80%;
text-align:left;
margin-left:2%;
margin-top:10px;

@media screen and (max-width: 650px) {
  margin-top: 10px; /* Adjust as needed */
  margin-left: 2%; /* Adjust as needed */
  width: 96%; /* Adjust as needed */
    padding:10px;
  }

`

const ImageContainer = styled.img`
  width: 50%;
  height: 50%;
  // background-image: url(${img1});
  background-size:cover;

  @media screen and (max-width: 650px) {
    width: 100%;
    height:100%;
    object-fit:cover;


  }
`;

const ImageContainerRight = styled.img`
// background-image: url(${img2});
background-size:cover;
  width: 50%;
  height: 50%;
  float: right;


  @media screen and (max-width: 650px) {
    width: 100%;
    height:100%;

  }

  @media screen and (max-width: 820px) {

  }
`;

const FooterContainer = styled.footer`
  background: gray;
  height: 80px; /* Adjust the height of the footer as needed */
  margin-top: auto; /* Add this line */
`;

function Service2() {

    const [isMoblie, setIsMobile] = useState(false);
    const [isBigScreen, setIsBigScreen] = useState(false);

    const windowSize = useRef([window.innerWidth, window.innerHeight])
    useEffect(() => {
  
      if(windowSize.current[0] <= 650){
          setIsMobile(!false);
        }else if(windowSize.current[0] >= 810){
          setIsBigScreen(true);
        } 
    })



  return (
    <ServiceContainer>
      <ServiceBody>
        <InfoContainer className='info-container'>
         {isBigScreen && (<div style={{width:"100%", height:"100%"}}>
            <DescriptionContainerRight>
            <DescriptionContainerRightHeader>
          <h2 style={{color:"#00c9bd",}} className="font-face-gm"><strong>Always connected<ImConnection style={{color:"#00c9bd",marginLeft:"10px", marginBottom:"10px"}}/></strong></h2>
            </DescriptionContainerRightHeader>
            <DescriptionContainerRightText style={{fontFamily: "Montserrat', sans-serif"}}>
          <p style={{color:"black",marginTop:"-5px"}}>Ensure peace of mind for your <strong>Martha's Vineyard</strong> 🏠 home with iLand Tech. Our expert team specializes in security camera installations. The cameras provide round-the-clock monitoring and reliable notifications. Leave your property worry-free as our advanced surveillance technology diligently watches over your home. Experience the tranquility wherever you go while our cameras keep you informed and protected. Discover the unmatched security and peace of mind that iLand Tech brings to your Martha's Vineyard residence.</p>
          <p style={{ color:"black", marginTop:"",height:"20px"}}>From smart TVs to security systems, from connected appliances to voice-controlled assistants, we've got you covered. <strong>Our skilled professionals</strong>  are equipped with the latest tools and knowledge to handle any technological challenge, ensuring everything is in perfect working order.</p>
            </DescriptionContainerRightText>
          </DescriptionContainerRight>
          <ImageContainer src={img1}/>
          <ImageContainerRight src={img2}/>
          <DescriptionContainer>
          <DescriptionContainerHeader>
          <h2 style={{color:"#00c9bd",}} className="font-face-gm"><strong>Seamless Experience<BsCloudCheckFill style={{marginLeft:"10px", marginBottom:"0px"}}/></strong></h2>
            </DescriptionContainerHeader>
            <DescriptionContainerText style={{fontFamily: "Montserrat', sans-serif"}}>
          <p style={{color:"black",}} >Are you tired of dealing with slow and unreliable internet connections? Say goodbye to buffering and lag with our seamless <strong>WiFi experience.</strong>  We understand the frustration that comes with interrupted online activities and the need for a strong, stable connection. That's why we've provide WiFi service that covers you with a hassle-free and uninterrupted <strong>online experience.</strong> </p>
          <p style={{color:"black",marginTop:"-8px"}}>Experience the freedom and convenience of seamless WiFi. We take pride in delivering a reliable and high-performance internet solutions that enhances your <strong>digital lifestyle</strong>. </p>
            </DescriptionContainerText>
          </DescriptionContainer>
         </div>)}

         {isMoblie && (<>
            <ImageContainer src={img1}/>
            <DescriptionContainerRight>
            <DescriptionContainerRightHeader>
          <h2 style={{color:"#00c9bd",}} className="font-face-gm"><strong>Always connected<ImConnection style={{marginLeft:"10px", marginBottom:"10px"}}/></strong></h2>
            </DescriptionContainerRightHeader>
            <DescriptionContainerRightText style={{fontFamily: "Montserrat', sans-serif"}}>
          <p style={{color:"black",}}>Imagine leaving your <strong>Martha's Vineyard 🏠</strong>  home worry-free, knowing that our team of expert technicians is taking care of your house's technology while you're away. Our goal is to ensure that all your electronic devices are connected to the network and functioning flawlessly when you return.</p>
          <p style={{color:"black",}}>From smart TVs to security systems, from connected appliances to voice-controlled assistants, we've got you covered. <strong>Our skilled professionals</strong>  are equipped with the latest tools and knowledge to handle any technological challenge, ensuring everything is in perfect working order.</p>
            </DescriptionContainerRightText>
          </DescriptionContainerRight>
          <ImageContainerRight src={img2}/>
          <DescriptionContainer>
          <DescriptionContainerHeader>
          <h2 style={{color:"#00c9bd",}} className="font-face-gm"><strong>Seamless Experience<BsCloudCheckFill style={{marginLeft:"10px", marginBottom:"0px"}}/></strong></h2>
            </DescriptionContainerHeader>
            <DescriptionContainerText style={{fontFamily: "Montserrat', sans-serif"}}>
            <p style={{color:"black"}} >Are you tired of dealing with slow and unreliable internet connections? Say goodbye to buffering and lag with our seamless <strong>WiFi experience.</strong>  We understand the frustration that comes with interrupted online activities and the need for a strong, stable connection. That's why we've provide WiFi service that covers you with a hassle-free and uninterrupted <strong>online experience.</strong> </p>
            <p style={{color:"black"}}>Experience the freedom and convenience of seamless WiFi. We take pride in delivering a reliable and high-performance internet solutions that enhances your digital lifestyle. Don't settle for less when it comes to your online experience. <strong>Stay connected</strong>,  stay productive, and stay entertained with our tech solutions.</p>
            </DescriptionContainerText>
          </DescriptionContainer>
         </>
          )}
        </InfoContainer>
      </ServiceBody>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </ServiceContainer>
  );
}

export default Service2;
