import React from 'react'
import styled from 'styled-components'

export const Colors ={
  linearbBlue: "radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%);"
}
export const ServiceContainer = styled.div`
// background:black;
margin:auto;




@media screen and (max-width: 650px){
    width:80%;
    margin-top:2rem;
  }
`
export const ServiceBody = styled.div`
// display:inline-table;
max-width:250px;
// background:green;
margin:auto;

`
export const ServiceImage = styled.div`
width:100%;
// min-height: 100%;
display:inline-flex;
justify-content:center;
// background:brown;
object-fit: cover;
overflow: hidden;
border-bottom: 5px solid;
// border-image: linear-gradient(to right, rgb(30, 0, 139), rgb(50, 135, 204)) 1;
// border-color:#00c9bd;
border-color:#0CC0DF;
border-radius:5px;


&:hover{
  border-color:#00c9bd;
  cursor:pointer;
}

`
export const ServiceDescription = styled.div`
// width:100%;
// height:100%;
// background:orange;
display:flex;
justify-content:center;
overflow: auto;
`

const switchUrlInSameTab = (url) => {
  window.location.href = url;
};

function Service(props) {
  return (
    <ServiceContainer >
        <ServiceBody>
            <ServiceImage>
                <img onClick={() => switchUrlInSameTab(`${props.url}`)} style={{width:"250px", }} src={props.img} alt={props.alt}/>
            </ServiceImage>
            <ServiceDescription>
                <h5 className="font-face-gm">{props.title} </h5>
            </ServiceDescription>
        </ServiceBody>
    </ServiceContainer>
  )
}

export default Service